import React from "react";
import styled from "@emotion/styled";
import { StaticQuery, graphql } from "gatsby";
import GitHubButton from "react-github-btn";
import Link from "./link";
import Loadable from "react-loadable";

import config from "../../config.js";
import LoadingProvider from "./mdxComponents/loading";
import { DarkModeSwitch } from "./DarkModeSwitch";

const help = require("./images/help.svg");

const isSearchEnabled =
  config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];

if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`
  });
}

import Sidebar from "./sidebar";

const LoadableComponent = Loadable({
  loader: () => import("./search/index"),
  loading: LoadingProvider
});

function myFunction() {
  var x = document.getElementById("navbar");

  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}

const StyledBgDiv = styled("div")`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${props => (props.isDarkThemeActive ? "#001932" : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const Header = ({ location, isDarkThemeActive, toggleActiveTheme }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            githubUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={data => {
      const twitter = require("./images/twitter.svg");

      const {
        site: {
          siteMetadata: {
            headerTitle,
            githubUrl,
            helpUrl,
            tweetText,
            logo,
            headerLinks
          }
        }
      } = data;

      const finalLogoLink = logo.link !== "" ? logo.link : "https://hasura.io/";

      return (
        <div className={"navBarWrapper"}>
          <nav className={"navBarDefault"}>
            <div className={"navBarHeader"}>
              <div
                className={"headerTitle displayInline"}
                dangerouslySetInnerHTML={{ __html: headerTitle }}
              />
            </div>
            {config.header.social ? (
              <ul
                className="socialWrapper visibleMobileView"
                dangerouslySetInnerHTML={{ __html: config.header.social }}
              ></ul>
            ) : null}
            {isSearchEnabled ? (
              <div className={"searchWrapper hiddenMobile navBarUL"}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
            ) : null}
            <div id="navbar" className={"topnav"}>
              <div className={"visibleMobile"}>
                <Sidebar location={location} />
                <hr />
              </div>
              <ul className={"navBarUL navBarNav navBarULRight"}>
                {headerLinks.map((link, key) => {
                  if (link.link !== "" && link.text !== "") {
                    return (
                      <li key={key}>
                        <a
                          className="sidebarLink"
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          dangerouslySetInnerHTML={{ __html: link.text }}
                        />
                      </li>
                    );
                  }
                })}
                {helpUrl !== "" ? (
                  <li>
                    <a href={helpUrl}>
                      <img src={help} alt={"Help icon"} />
                    </a>
                  </li>
                ) : null}

                {tweetText !== "" ? (
                  <li>
                    <a
                      href={
                        "https://twitter.com/intent/tweet?&text=" + tweetText
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={"shareIcon"}
                        src={twitter}
                        alt={"Twitter"}
                      />
                    </a>
                  </li>
                ) : null}
                {tweetText !== "" || githubUrl !== "" ? (
                  <li className="divider hiddenMobile"></li>
                ) : null}
                {config.header.social ? (
                  <li className={"hiddenMobile"}>
                    <ul
                      className="socialWrapper"
                      dangerouslySetInnerHTML={{ __html: config.header.social }}
                    ></ul>
                  </li>
                ) : null}
                {githubUrl !== "" ? (
                  <li className={"githubBtn"}>
                    <GitHubButton
                      href={githubUrl}
                      data-show-count="true"
                      aria-label="Star on GitHub"
                    >
                      Star
                    </GitHubButton>
                  </li>
                ) : null}
                <li>
                  <DarkModeSwitch
                    isDarkThemeActive={isDarkThemeActive}
                    toggleActiveTheme={toggleActiveTheme}
                  />
                </li>
              </ul>
            </div>
          </nav>
          <StyledBgDiv isDarkThemeActive={isDarkThemeActive}>
            <div className={"navBarDefault removePadd"}>
              <span
                onClick={myFunction}
                className={"navBarToggle"}
                onKeyDown={myFunction}
                role="button"
                tabIndex={0}
              >
                <span className={"iconBar"}></span>
                <span className={"iconBar"}></span>
                <span className={"iconBar"}></span>
              </span>
            </div>
            {isSearchEnabled ? (
              <div className={"searchWrapper"}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
            ) : null}
          </StyledBgDiv>
        </div>
      );
    }}
  />
);

export default Header;
